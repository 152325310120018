import store from "../store";
import { axiosAuth } from "../utils/axiosInterceptor";
import { customArraySort, customItemSort, removeAllStoresOptn } from "../utils/formatter";


const getDivisionAPI = async (): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.get(`${apiBaseUrl}/deli/division`)
    // return await axiosAuth.get(`${apiBaseUrl}/deli/division/v2`) //v2 added for new edm dependency
        .then((responseDta) => {
            return { response: responseDta.data };
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const getStoreAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.get(`${apiBaseUrl}/deli/store/${value}`)
    // return await axiosAuth.get(`${apiBaseUrl}/deli/store/${value}/v2`) //v2 added for new edm dependency
        .then((responseDta) => {
            return { response: responseDta.data };
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const getItemGrpAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.post(`${apiBaseUrl}/deli/getItemGroupForAdmin/v2`, {
        divId: value.divId,
        storeId: value.storeId
    })
        .then((responseDta) => {
            if (responseDta.data && responseDta.data?.itemGroupList) responseDta.data.itemGroupList = customArraySort(responseDta.data.itemGroupList, 'groupName');
            return { response: responseDta.data };
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const addItemGrpAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    let valueClone = JSON.parse(JSON.stringify(value));
    valueClone.storeId = removeAllStoresOptn(value.storeId);
    return await axiosAuth.post(`${apiBaseUrl}/deli/addItemGroup`, valueClone)
        .then((responseDta) => {
            return { addResponse: responseDta.data }
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const getItemCICAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.post(`${apiBaseUrl}/deli/getItemDetails/v2`, {
        div: value.divId,
        stores: value.storeId,
        groupId: value.itemGroupId
    })
        .then((responseDta) => {
            if (responseDta.data && responseDta.data?.products) responseDta.data.products = customItemSort(responseDta.data.products);
            return { response: responseDta.data };
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const deleteCicAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    let valueClone = JSON.parse(JSON.stringify(value));
    value.valueClone = removeAllStoresOptn(value.storeId);
    return await axiosAuth.post(`${apiBaseUrl}/deli/deleteItem`, valueClone)
        .then((responseDta) => {
            return { responseDelete: responseDta.data };
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const updateCicAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.post(`${apiBaseUrl}/deli/saveItemData`, value)
        .then((responseDta) => {
            return { responseUpdate: responseDta.data };
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const getCicAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.get(`${apiBaseUrl}/deli/validateItem/${value.divId}/${value.groupId}/${value.cicId}/v2`)
        .then((responseDta) => {
            return { response: responseDta.data };
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const addCicApi = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.post(`${apiBaseUrl}/deli/addItem/v2`, value)
        .then((responseDta) => {
            return { addCicResponse: responseDta.data }
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const updateItemGrpAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;

    return await axiosAuth.post(`${apiBaseUrl}/deli/updateItemGroup`, value)
        .then((responseDta) => {
            return { updateResponse: responseDta.data }
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const deleteItemGrpAPI = async (value: any): Promise<any> => {
    const apiBaseUrl = store?.getState()?.globalDtl?.globalDtl?.env?.apiUrl;
    return await axiosAuth.post(`${apiBaseUrl}/deli/deleteItemGroup`, value)
        .then((responseDta) => {
            return { deleteResponse: responseDta.data }
        })
        .catch((err) => {
            return { err: JSON.parse(err) }
        })
}

const adminEndpoints = {
    getDivisionAPI,
    getStoreAPI,
    getItemGrpAPI,
    getItemCICAPI,
    deleteCicAPI,
    updateCicAPI,
    getCicAPI,
    addCicApi,
    addItemGrpAPI,
    updateItemGrpAPI,
    deleteItemGrpAPI
};

export default adminEndpoints;
